<template>
  <div>
    <el-container>
      <el-header height="100%">
        <component :is="headerComName" />
        <div v-if="$route.meta.type !== '6'" class="top-img">
          <img
            width="100%"
            :src="topImgPath"
            alt=""
          >
          <div class="top-img-top">
            <p>{{ $route.meta.title1 }}</p>
            <span>{{ $route.meta.title2 }}</span>
          </div>
        </div>
        <div v-else style="padding-top: 72px;"></div>
      </el-header>
      <el-main class="CityH1">
        <div class="w">
          <div
            v-loading="listLoading"
            class="kr-content"
          >
            <div class="kr-s1">
              <div class="kr-col1">
                <img
                  class="g1"
                  :src="$route.meta.type !== '6' ? g1ImgPath : g2ImgPath"
                  alt=""
                >
              </div>
              <div class="kr-col2">
                <el-input
                  v-model="listQuery.searchTxt"
                  :placeholder="$i18n.locale === 'en' ? 'search' : '输入关键字搜索'"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-search"
                    @click="handleFilter"
                  ></i>
                </el-input>
              </div>
            </div>
            <div class="kr-s2">
              <div
                v-for="row in list"
                :key="row.key"
                class="kr-row"
                @click="$route.meta.type !== '6' ? handleToDetail(row) : handleToVideoDetail(row)"
              >
                <div class="kr-col1">
                  <div v-if="$route.meta.type !== '6'" class="g1Box">
                    <img
                      class="g1"
                      :src="row.image"
                      alt=""
                    >
                  </div>
                  <div v-else class="g1Box">
                    <video
                      class="g1"
                      playsinline
                      preload="metadata"
                    >
                      <source
                        :src="row.video + '#t=0.1'"
                        type="video/mp4"
                      >
                    </video>
                  </div>
                </div>
                <div class="kr-col2">
                  <div class="kr-t1">{{ row.title }}</div>
                  <div class="kr-t2">{{ row.stateDate | format('yyyy.MM.dd') }}</div>
                </div>
              </div>
            </div>
            <div class="kr-s3">
              <el-pagination
                background
                :current-page="listQuery.page"
                :page-size="listQuery.limit"
                layout="prev, pager, next, sizes"
                :page-sizes="[10, 20, 50]"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
        <gotop></gotop>
      </el-main>
      <el-footer>
        <component :is="footerComName" />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Gotop from '../../components/gotop'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    Gotop
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        searchTxt: ''
      },
      total: 0,
      list: [],
      listLoading: false
    }
  },
  computed: {
    ...mapGetters(['devicePathStr', 'langPathStr', 'headerComName', 'footerComName', 'curHide']),
    topImgPath() {
      return require(`../../assets/images/H/topImg-${this.$route.meta.type}${this.devicePathStr}.png`)
    },
    g1ImgPath() {
      return require(`../../assets/images/H/g1-${this.$route.meta.type}${this.langPathStr}${this.devicePathStr}.png`)
    },
    g2ImgPath() {
      return require(`../../assets/images/H/g2${this.langPathStr}${this.devicePathStr}.png`)
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    async getList() {
      this.listLoading = true
      try {
        const res = await axios.get(`web/activity${ this.$route.meta.type !== '6' ? '' : '/video' }/list`, {
          params: {
            type: this.$route.meta.type,
            hide: this.curHide,
            pageNum: this.listQuery.page,
            pageSize: this.listQuery.limit,
            keyword: this.listQuery.searchTxt
          }
        })
        if (res && res.data && res.data.code === 200) {
          this.list = res.data.data.list
          this.total = res.data.data.total
        }
        console.log(res);
      } catch (error) {
        console.log(error)
      }
      this.listLoading = false
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleToDetail(row) {
      console.log(row);
      this.$router.push({
        name: this.$i18n.locale === 'en' ? 'ActiveDetaile' : 'ActiveDetail',
        params: {
          detailId: row.id
        }
      })
    },
    handleToVideoDetail(row) {
      console.log(row);
      this.$router.push({
        name: this.$i18n.locale === 'en' ? 'VideoDetaile' : 'VideoDetail',
        params: {
          detailId: row.id
        }
      })
    }
  }
}
</script>

<style
  scoped
  lang="less"
>
.CityH1 {
  font-family: Microsoft YaHei, Microsoft YaHei;

  .kr-content {
    max-width: 800px;
    margin: auto;
    position: relative;

    .kr-s1 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 20px;

      .kr-col1 {
        width: 540px;
        display: flex;

        .g1 {
          width: 450px;
        }
      }

      .kr-col2 {
        flex: auto;

        /deep/ .el-input {
          .el-input__inner {
            background: #E0E0E0;
            border-radius: 20px 20px 20px 20px;
            color: white;

            &::placeholder {
              color: white;
            }
          }

          .el-input__prefix {
            color: white;
            cursor: pointer;
          }
        }
      }
    }

    .kr-s2 {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .kr-row {
        display: flex;
        flex-wrap: wrap;
        background: #FFFFFF;
        box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.08);
        border-radius: 4px 4px 4px 4px;
        cursor: pointer;

        .kr-col1 {
          width: 400px;
          background: #ccc;
          border-radius: 4px 4px 4px 4px;
          overflow: hidden;

          .g1Box {
            position: relative;
            padding-right: 100%;
            padding-bottom: 50%;

            .g1 {
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }

        .kr-col2 {
          width: 400px;
          overflow: hidden;
          padding: 28px;
          text-align: left;

          .kr-t1 {
            font-weight: bold;
            font-size: 20px;
            color: #000000;
            line-height: 28px;
            min-height: 112px;
            max-height: 112px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .kr-t2 {
            padding-top: 20px;
            font-size: 14px;
            color: #A2A2A2;
            line-height: 12px;
          }
        }
      }
    }

    .kr-s3 {
      padding-top: 32px;
      padding-bottom: 60px;
      display: flex;
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width:1024px) {
  .CityH1 {
    .kr-content {
      .kr-s1 {
        .kr-col1 {
          width: 100%;

          .g1 {
            width: 227px;
          }
        }
      }

      .kr-s2 {
        .kr-row {
          .kr-col1 {
            width: 100%;
          }

          .kr-col2 {
            padding: 12px 20px;

            .kr-t1 {
              font-size: 16px;
              min-height: 56px;
              max-height: 56px;
              -webkit-line-clamp: 2;
            }

            .kr-t2 {
              padding-top: 10px;
            }
          }
        }
      }

      .kr-s3 {
        padding-top: 24px;
        padding-bottom: 32px;
      }
    }
  }
}
</style>

<style scoped>
.el-header {
  padding: 0;
}

.el-main {
  margin-top: 0;
  padding: 0;
  overflow: initial;
}

.el-main .w {
  padding: 15px;
}

.top-img {
  position: relative;
}

.top-img-top {
  position: absolute;
  top: 45%;
  left: 5%;
  color: #FFFFFF;
  text-align: left;
}

.top-img-top p {
  font-size: 0.6rem;
  font-weight: 600;
}

.top-img-top span {
  font-size: 0.45rem;
  font-weight: 300;
}

.top-img .top-img-top:after {
  content: '';
  position: absolute;
  left: 0;
  top: -16px;
  height: 1px;
  width: 40%;
  background: #FFF;
}
</style>
